//清除浮动
.clearfix {
	zoom: 1;
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after { 
		clear: both;
	}
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
#root {
  height: 100%;
  background: #f0f2f5;
}
.wx-register {
  width: 100%;
  height: 100%;
}
.wx-register img {
  display: block;
  width: 260px;
  height: 260px;
  margin: 60px auto;
}
.wx-register .text {
  width: 380px;
  margin: auto;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 60px;
}
.wx-register .text p {
  line-height: 28px;
  margin: 0;
  padding: 0;
}
.clearfix {
  zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-size: 20px;
}
.search-form {
  margin-bottom: 15px;
}
.ant-form-inline .ant-form-item {
  margin-bottom: 10px;
}
.detail-model {
  top: 40px;
  height: calc(100% - 40px);
  min-height: 600px;
}
.detail-model .ant-modal-content {
  height: 100%;
  position: relative;
}
.detail-model .ant-modal-body {
  max-height: calc(100% - 103px);
  height: calc(100% - 103px);
  min-height: 400px;
  overflow-y: auto;
}
.detail-model .ant-modal-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: #fff;
}
.list-total span {
  font-size: 16px;
  padding-right: 26px;
}
.table-optBtn button {
  margin-bottom: 24px;
}
.ant-descriptions-item-content {
  word-break: break-all;
}
