//清除浮动
.clearfix {
	zoom: 1;
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after { 
		clear: both;
	}
}
.main-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  background: #fff;
}
.layout-content {
  margin-top: 64px;
  padding: 24px 60px;
}
.main-hearder-dropdown {
  display: inline-block;
  height: 100%;
}
.left-sider {
  overflow-y: auto;
  position: fixed;
  left: 60px;
  top: 88px;
}
.main-sider {
  background: #fff;
}
.main-content {
  padding: 10px 15px;
  margin: 0;
  min-height: 280px;
  background: #fff;
}
#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}
.agreement {
  font-size: 16px;
  padding-bottom: 20px;
  line-height: 30px;
}
.agreement .title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}
.agreement .p {
  text-indent: 40px;
}
.agreement .bold {
  font-weight: bold;
}
